<template>
  <NotificationBase :notification :icon="{ name: icon }" :compact>
    <template #subject>
      {{
        t('notifications.subject.userAuthProviderCreated', {
          provider: notificationProvider,
        })
      }}
    </template>
    <p class="text">
      {{
        t('notifications.body.userAuthProviderCreated', {
          provider: notificationProvider,
        })
      }}
    </p>
  </NotificationBase>
</template>

<script setup lang="ts">
import type { UserAuthProviderCreated } from '../../../../types'
import NotificationBase from '../NotificationBase.vue'

const { t } = useI18n()

const { notification, compact = false } = defineProps<{
  notification: UserAuthProviderCreated
  compact?: boolean
}>()

const notificationProvider = computed(
  () => ({ telegram: 'Telegram' })[notification.data.provider],
)

const icon = computed(
  () =>
    ({
      telegram: 'telegram-solid' as const,
    })[notification.data.provider],
)
</script>
